import React from "react"

import { FormattedMessage } from 'react-intl'

import Layout from "../components/layout"
import SEO from "../components/seo"

const RequirementsPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Yhteensopivuus"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                <h1><FormattedMessage id="Requirements" /></h1>
                </div>
                <div className={"row"}>
                    <div className={"col-7"}>
                        <p>
                            <ul className={"requirements-list"}>
                                <li><FormattedMessage id="Requirement1" /></li>
                                <li><FormattedMessage id="Requirement2" /></li>
                                {/* <li><FormattedMessage id="Requirement3" /></li> */}
                            </ul>
                        </p>
                        <p><FormattedMessage id="RequirementAdditional" /></p>
                    </div>

                    <div className={"col-5"}>
                        <div className={"contact-items"}>
                            <p><FormattedMessage id="HelpText" /></p>
                            <h4>info@reko.ai</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default RequirementsPage
